import { AxiosInstance } from "axios";
import moment from "moment";
import { getCookie, setCookie } from "cookies-next";
import { ISession } from "@/models";
import { REFRESH_TOKEN_COOKIE_KEY, TOKEN_COOKIE_KEY } from "@/const";

/**
 * Set the token and refresh token on the Cookies api.
 *
 * @param data An ISession object that contains all tokens related data.
 * @param req The request object (comes from Next)
 * @param res The response object (comes from Next)
 */
export function setAuthCookies(data: ISession, req?: any, res?: any) {
  let configToken: any = {
    expires: moment(data.accessTokenExpiresAt).toDate(),
  };

  let configRefreshToken: any = {
    expires: moment(data.refreshTokenExpiresAt).toDate(),
  };

  if (req && res) {
    configToken["req"] = req;
    configToken["res"] = res;
    configRefreshToken["req"] = req;
    configRefreshToken["res"] = res;
  }

  setCookie(TOKEN_COOKIE_KEY, data.accessTokenValue, configToken);

  setCookie(
    REFRESH_TOKEN_COOKIE_KEY,
    data.refreshTokenValue,
    configRefreshToken
  );
}

/**
 * Return an object that must to be the return for not permanet redirect on Nextjs Apps.
 *
 * @param destination The path to redirect to.
 *
 * @returns The object that indicate that the redirect is not permanent.
 * See: https://nextjs.org/docs/pages/api-reference/next-config-js/redirects
 */
export function getNotPermanentRedirectObj(destination: string) {
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
}

/**
 * Prevent the page from being accessed by a non-logged in user.
 *
 * @param context Next context object.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#context-parameter
 *
 * @param callback A callback function.
 *
 * @returns Next return object or a callback function that returns it.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#getserversideprops-return-values
 */
export async function routeGuardForNonLoggedUser(
  context: any,
  // eslint-disable-next-line no-unused-vars
  callback?: (param: any) => any
) {
  const { req, res } = context;
  const pathToRedirect = "/auth/login";
  const token = getCookie(TOKEN_COOKIE_KEY, { req, res });

  if (!token) {
    return getNotPermanentRedirectObj(pathToRedirect);
  }

  return !callback ? { props: {} } : callback(context);
}

/**
 * Prevent the page from being accessed by a logged in user.
 *
 * @param context Next context object.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#context-parameter
 *
 * @param callback A callback function.
 *
 * @returns Next return object or a callback function that returns it.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#getserversideprops-return-values
 */
export async function routeGuardForLoggedUser(
  context: any,
  // eslint-disable-next-line no-unused-vars
  callback?: (param: any) => any
) {
  const { req, res } = context;
  const pathToRedirect = "/";
  const token = getCookie(TOKEN_COOKIE_KEY, { req, res });

  if (token) {
    return getNotPermanentRedirectObj(pathToRedirect);
  }

  return !callback ? { props: {} } : callback(context);
}

/**
 * Try to add the Barear token to the request header.
 * If the token does not exist or it is invalid, try to refresh it.
 *
 * @param api The AxiosInstance object
 */
export async function addTokenToClientSideRequest(api: AxiosInstance) {
  api.interceptors.request.use(async (config) => {
    // If the token does not exist, try to refresh it (clientside)
    let token = getCookie(TOKEN_COOKIE_KEY);

    // It is not a else for the above condition
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
}

let queueRefresh: boolean = false;

export async function refreshTokenInterceptor(api: AxiosInstance) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401 && !queueRefresh) {
        const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_KEY) as string;

        if (refreshToken) {
          queueRefresh = true;
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_URL}/sessions/refresh`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ refreshToken }),
            }
          ).finally(() => {
            queueRefresh = false;
          });

          if (response.status === 200) {
            const data = await response.json();
            if (data?.accessTokenValue) {
              setAuthCookies(data);
            }
          } else {
            window.location.href = "/auth/logout";
          }
        }
      }
      throw error;
    }
  );
}
