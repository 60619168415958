/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { useLocale } from "@/hooks";

import "react-phone-input-2/lib/style.css";
import styles from "./index.module.scss";

type InputPhoneProps = {
  value?: string;
  onChange?(param: string): void;
  hasError?: boolean;
  label?: string;
  id?: string;
};

export default function InputPhone({
  value = "",
  onChange,
  hasError = false,
  label,
  id,
  ...props
}: InputPhoneProps & PhoneInputProps) {
  const { locale } = useLocale();
  const [phoneInputLocale, setPhoneInputLocale] = useState<any>({});
  const [isVisible, setIsVisible] = useState(true);
  let localeObj: any = {}; // it is empty because English is the default.
  let timer: any;

  useEffect(() => {
    async function loadPhoneInput2Locale() {
      switch (locale) {
        case "es":
          // @ts-ignore
          localeObj = await import("react-phone-input-2/lang/es.json");
          break;
        case "pt":
          // @ts-ignore
          localeObj = await import("react-phone-input-2/lang/pt.json");
          break;
      }
    }

    if (locale) {
      loadPhoneInput2Locale().then(() => {
        setPhoneInputLocale(localeObj);

        setIsVisible(false);
        timer = setTimeout(() => {
          setIsVisible(true);
        }, 10);
      });
    }

    return () => {
      clearTimeout(timer);
    };
  }, [locale]);

  return (
    <div
      className={`InputPhone ${styles.container} ${hasError ? styles.hasError : ""
        }`}
    >
      {label && <label htmlFor={id}>{label}</label>}

      {isVisible && (
        <PhoneInput
          localization={phoneInputLocale}
          country={"br"}
          value={value}
          enableSearch
          onChange={(phone: any) => {
            if (onChange) {
              onChange(phone);
            }
          }}
          inputClass={styles.input}
          buttonClass={styles.button}
          dropdownClass={styles.dropdown}
          searchClass={styles.search}
          inputProps={{ id }}
          autocompleteSearch
          {...props}
        />
      )}
    </div>
  );
}
