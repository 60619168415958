/* eslint-disable no-unused-vars */
import { ReactElement } from "react";
import { default as SlickSlider } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./index.module.scss";

type SliderItemProps = {
  children: any;
};

export function SliderItem({ children }: SliderItemProps) {
  return <div>{children}</div>;
}

type SliderProps = {
  children:
    | ReactElement<SliderItemProps>
    | Array<ReactElement<SliderItemProps>>;
  mobileStyle?: boolean; // will disable the overflow hidden and hide the dots
  settings?: any; // check all options at: https://react-slick.neostack.com/docs/api
};

export default function Slider({
  children,
  mobileStyle = false,
  settings,
}: SliderProps) {
  const defaultSettings = {
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    accessibility: true,
    ...settings,
  };

  return (
    <div
      className={`Slider ${styles.container} ${
        mobileStyle ? styles.mobileStyle : ""
      }`}
    >
      <SlickSlider {...defaultSettings}>{children}</SlickSlider>
    </div>
  );
}
