import { Logo } from "@/widgets";
import styles from "./index.module.scss";

export default function PoweredBy() {
  return (
    <div className={`PoweredBy ${styles.container}`}>
      <span>Powered by</span>
      <Logo variant="transfero" asLink={true} href="#" />
    </div>
  );
}
