import { IBalance, IConvertedBalance } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const BalancesApi = {
  getBalances: async () => {
    try {
      const response = await api.request({
        url: "/balances",
        method: "GET",
      });
      return response.data as IBalance[];
    } catch (error: any) {
      sendMessageSentry(
        `Balances Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getBalances");
    }
  },
  getBalancesByFiat: async (fiatCurrencyId: string) => {
    try {
      const response = await api.request({
        url: `/balances/currencies/${fiatCurrencyId}`,
        method: "GET",
      });
      return response.data as IConvertedBalance[];
    } catch (error: any) {
      sendMessageSentry(
        `Balances Currencies Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getBalancesByFiat");
    }
  },
  getConsolidatedBalance: async (currencyId: string) => {
    try {
      const response = await api.request({
        url: `/balances/consolidated/${currencyId}`,
        method: "GET",
      });
      return response.data as IBalance;
    } catch (error: any) {
      sendMessageSentry(
        `Balances Consolidated Currencies Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getConsolidatedBalance");
    }
  },
};

export default BalancesApi;
