import Logo from "@/widgets/Logo";
import * as S from "./styles";
import { t } from "i18next";

const MobileFooter = () => {
  return (
    <S.MobileFooter>
      <S.ItemsContainer>
        <S.ListItem>
          <a href="/help-center/limits-info">{t("nonLoggedInMenu.limits")}</a>
        </S.ListItem>
        <S.ListItem>
          <a href="/help-center/fees-info">{t("nonLoggedInMenu.feesInfo")}</a>
        </S.ListItem>
        <S.ListItem>
          <a href="/help-center/terms">{t("nonLoggedInMenu.userTerms")}</a>
        </S.ListItem>
        <S.ListItem>
          <a href="/help-center/privacy-policies">
            {t("nonLoggedInMenu.privacyPolicy")}
          </a>
        </S.ListItem>
        <S.ListItem>
          <a href="/help-center/contact">{t("nonLoggedInMenu.contact")}</a>
        </S.ListItem>
        <S.ListItem>
          <a href="https://ajudatransfero.zendesk.com/hc/pt-br">FAQ</a>
        </S.ListItem>
      </S.ItemsContainer>
      <S.PoweredByContainer>
        <p>Powered by</p>
        <S.Logo>
          <Logo asLink href="/" variant={"white-small"} />
        </S.Logo>
      </S.PoweredByContainer>
    </S.MobileFooter>
  );
};

export default MobileFooter;
