import { HelpCenterLayout } from "../components";

import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { LimitsTable } from "./LimitsTable";
import { useCustomer } from "@/store/features/customer";
import { individualLevels } from "@/utils";
import Link from "next/link";

export default function LimitsPage() {
  const { t } = useTranslation();
  const { customerState } = useCustomer();
  const userLevel = customerState.customer?.data?.profileLevel;
  const setSumsub = () => individualLevels.some((level) => level === userLevel);

  const userLimits = {
    brazilian: {
      individual: [
        {
          level: "Basic",
          fiat: {
            monthly: { value: 0, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
          crypto: {
            monthly: { value: 0, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
        },
        {
          level: "Standard",
          fiat: {
            monthly: { value: 2000, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
          crypto: {
            monthly: { value: 2000, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
        },
        {
          level: "Premium",
          fiat: {
            monthly: {
              value: 50000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
          crypto: {
            monthly: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 200000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
        },
        {
          level: "Premium 2",
          fiat: undefined,
          crypto: undefined,
          noLimitFiat: {
            monthly: {
              value: 50000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
          noLimitCrypto: {
            monthly: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 200000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
        },
      ],
      company: [
        {
          level: "Basic",
          fiat: {
            monthly: { value: 0, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
          crypto: {
            monthly: { value: 0, currency: "BRL", periodic: "" },
            semiannual: { value: "", currency: "BRL", periodic: "" },
          },
        },
        {
          level: "Standard Company",
          fiat: {
            monthly: {
              value: 50000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
          crypto: {
            monthly: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 200000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
        },
        {
          level: "Premium Company",
          fiat: undefined,
          crypto: undefined,
          noLimitFiat: {
            monthly: {
              value: 50000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
          noLimitCrypto: {
            monthly: {
              value: 100000,
              currency: "BRL",
              periodic: t("userLimits.month"),
            },
            semiannual: {
              value: 200000,
              currency: "BRL",
              periodic: t("userLimits.semester"),
            },
          },
        },
      ],
    },
  };

  return (
    <HelpCenterLayout pageTitle={t("userLimits.title")}>
      <div className={styles.limitsPageContainer}>
        <p className={styles.description}>{t("userLimits.description")}</p>
        <h4>{t("userLimits.brazilianSection")}</h4>
        <div className={styles.limitsContainer}>
          <h3>{t("userLimits.individual")}</h3>
          <LimitsTable limits={userLimits.brazilian.individual} />
        </div>
        <div className={styles.limitsContainer}>
          <h3>{t("userLimits.company")}</h3>
          <LimitsTable limits={userLimits.brazilian.company} />
        </div>

        <h4>{t("userLimits.otherCountriesSection")}</h4>
        <div className={styles.limitsContainer}>
          <h3>{t("userLimits.individual")}</h3>
          <LimitsTable limits={userLimits.brazilian.individual} />
        </div>
        {setSumsub() && (
          <>
            <p>
              {t("userLimits.currentProfile")} <strong>{userLevel}</strong>
            </p>
            <Link href="/sumsub">{t("userLimits.upgradeLink")}</Link>
          </>
        )}
      </div>
    </HelpCenterLayout>
  );
}
