import { BalancePerfomanceProps } from "./interfaces";
import * as S from "./styles";

const BalancePerformance = ({
  isPositive,
  performance,
}: BalancePerfomanceProps) => {
  return (
    <S.BalancePerformance $isPositive={isPositive}>
      {performance}
    </S.BalancePerformance>
  );
};

export default BalancePerformance;
