import { CryptoMarket, Slider, SliderItem } from "@/widgets";
import { IMarket } from "@/models";
import { useMarket } from "@/store/features/market";
import scssVariables from "@/styles/variables.module.scss";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { unitPriceMask } from "@/utils/masks";

export default function CurrencySlider() {
  const { t } = useTranslation();
  const { marketState } = useMarket();

  const formatPrice = (item: IMarket) => {
    return item.fromCurrency.id === "ARZ"
      ? unitPriceMask(item.unitPrice)
      : item.formattedUnitPrice;
  };

  const renderItems = () => {
    if (marketState.markets.data !== null) {
      return marketState.markets.data.map((item: IMarket) => {
        return (
          <SliderItem key={item.fromCurrency.id}>
            <CryptoMarket
              name={item.fromCurrency.id}
              value={formatPrice(item)}
              variation={item.pricePercentChange24H}
              absoluteImageUrl={item.fromCurrency.logoUrl}
            />
          </SliderItem>
        );
      });
    }
    return <></>;
  };

  return (
    <>
      {marketState.markets.data !== null && (
        <div className={`${styles.container}`}>
          <h2>{t("availableCurrencies")}</h2>
          <Slider
            mobileStyle={true}
            settings={{
              slidesToShow: 5,
              responsive: [
                {
                  breakpoint: parseInt(scssVariables.breakpointXL),
                  settings: {
                    slidesToShow: 5,
                  },
                },
                {
                  breakpoint: parseInt(scssVariables.breakpointLG),
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: parseInt(scssVariables.breakpointMD),
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: parseInt(scssVariables.breakpointSM),
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: parseInt(scssVariables.breakpointXS),
                  settings: {
                    slidesToShow: 1,
                    centerMode: true,
                  },
                },
              ],
            }}
          >
            {renderItems()}
          </Slider>
        </div>
      )}
    </>
  );
}
