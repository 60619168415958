import { Actions, AppAbility, Subjets } from "./context";
import { FeatureFlag } from "@/domains/models";
import { NextRouter } from "next/router";

type AbilityPermission = { action: Actions; subject: Subjets }[];

export function generateAbility(param: FeatureFlag): AbilityPermission {
  const features: AbilityPermission = [];

  for (const [featureName, isEnabled] of Object.entries(param?.features)) {
    if (isEnabled) {
      features.push({
        action: featureName as Actions,
        subject: "All",
      });
    }
  }

  return features;
}

export function redirectNotAbility(
  abilities: AppAbility,
  navigate: NextRouter,
  isPrime?: boolean
): void {
  const whitelist = {
    "/card": "Cards",
    "/cardshowcase": "Cards",
    "/wallet": isPrime && "Investments",
    "/wallet/transactions": isPrime && "Investments",
    "/wallet/currencies": isPrime && "Investments",
    "/wallet/portfolio": isPrime && "Investments",
  };

  const path = navigate.pathname;
  const isWhiteList = whitelist[path as keyof typeof whitelist];

  if (!isWhiteList) return;

  if (
    !abilities.can(whitelist[path as keyof typeof whitelist] as Actions, "All")
  ) {
    navigate.push("/");
  }
}
