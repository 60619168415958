import styled from "styled-components";

export const MobileAppCardContainer = styled.div``;
export const TextContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: 40px;
`;

export const TextBlack = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
`;

export const TitleBlue = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: #4d4dff;
`;

export const TextDetails = styled.p`
  margin-top: 20px;
  font-family: Karla;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: var(--Gray-90, #30374f);
`;

export const ImageContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const ContentDownload = styled.div`
  margin-inline: 40px;
  h4 {
    font-family: Karla;
    font-size: 18px;
    color: #7d89b0;
    font-weight: 400;
    margin: 60px 0 15px;
  }
`;

export const Download = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`;

export const Button = styled.div`
  width: calc(70% - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  padding: 8px;
  background-color: #ebebff;
  box-shadow: 0px 6px 13.35px -6.68px #b6b6ff26;
  color: #413fcf;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: Karla;
  img {
    margin-right: 5px;
  }

  .img-google {
    margin-top: 2px;
  }
`;

export const ContentSlider = styled.div`
  margin-inline: 40px;
  font-family: Karla;
  margin-top: 30px;
  margin-bottom: 40px;
`;
