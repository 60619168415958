import styled from "styled-components";
import { PerfomanceProps } from "./interfaces";

export const BalancePerformance = styled.div<PerfomanceProps>`
  max-width: 100%;
  height: 1.5rem;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Karla", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: left;
  padding: 1rem;
  background: ${(props) => (props.$isPositive ? "#ecfdf3" : "#FFEFEF")};
  color: ${(props) => (props.$isPositive ? "#027a48" : "#D10000")};
`;
