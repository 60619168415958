import { useMemo } from "react";
import { useCustomer } from "@/store/features/customer";

export default function useCustomerInfo() {
  const { customerState } = useCustomer();

  const hasCustomer = useMemo(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("email", customerState.customer.data?.email);
      window.localStorage.setItem("id", customerState.customer.data?.id);
      window.localStorage.setItem(
        "profileLevel",
        customerState.customer.data?.profileLevel
      );
    }
    return customerState.customer.data !== null;
  }, [customerState.customer.data]);

  const hasTaxId = useMemo(() => {
    return !!customerState.customer.data?.taxId;
  }, [customerState.customer.data]);

  const isCompany = useMemo(() => {
    return customerState.customer.data?.type === "Company";
  }, [customerState.customer.data]);

  const isIndividual = useMemo(() => {
    return customerState.customer.data?.type === "Individual";
  }, [customerState.customer.data]);

  const hasRequiredFields = useMemo(() => {
    return (
      customerState.requiredFields.data !== null &&
      customerState.requiredFields.data !== undefined &&
      customerState.requiredFields.data.length > 0
    );
  }, [customerState.requiredFields.data]);

  const isBasicCompany =
    isCompany && customerState.customer.data?.profileLevel === "Basic";

  return {
    hasCustomer,
    hasTaxId,
    isCompany,
    isIndividual,
    hasRequiredFields,
    isBasicCompany,
  };
}
