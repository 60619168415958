import styles from "./index.module.scss";

import add from "./svgs/add";
import ArrowLeftCalendar from "./svgs/arrowLeftCalendar";
import ArrowRightCalendar from "./svgs/arrowRightCalendar";
import arrowBack from "./svgs/arrowBack";
import arrowDown from "./svgs/arrowDown";
import arrowLeft from "./svgs/arrowLeft";
import arrowRight from "./svgs/arrowRight";
import arrowUp from "./svgs/arrowUp";
import attention from "./svgs/attention";
import bell from "./svgs/bell";
import calendar from "./svgs/calendar";
import cellPhone from "./svgs/cellPhone";
import close from "./svgs/close";
import company from "./svgs/company";
import convert from "./svgs/convert";
import copy from "./svgs/copy";
import cotation from "./svgs/cotation";
import deposit from "./svgs/deposit";
import edit from "./svgs/edit";
import email from "./svgs/email";
import error from "./svgs/error";
import expired from "./svgs/expired";
import eye from "./svgs/eye";
import eyeHide from "./svgs/eyeHide";
import faq from "./svgs/faq";
import filter from "./svgs/filter";
import first from "./svgs/first";
import graphDown from "./svgs/graphDown";
import graphUp from "./svgs/graphUp";
import history from "./svgs/history";
import homeNoSelected from "./svgs/homeNoSelected";
import important from "./svgs/important";
import info from "./svgs/info";
import key from "./svgs/key";
import last from "./svgs/last";
import link from "./svgs/link";
import logout from "./svgs/logout";
import maintenance from "./svgs/maintenance";
import noConnection from "./svgs/noConnection";
import none from "./svgs/none";
import noResult from "./svgs/noResult";
import ok from "./svgs/ok";
import okDefault from "./svgs/okDefault";
import orderBy from "./svgs/orderBy";
import orderDown from "./svgs/orderDown";
import orderUp from "./svgs/orderUp";
import print from "./svgs/print";
import question from "./svgs/question";
import receipt from "./svgs/receipt";
import remove from "./svgs/remove";
import repeat from "./svgs/repeat";
import save from "./svgs/save";
import search from "./svgs/search";
import security from "./svgs/security";
import send from "./svgs/send";
import success from "./svgs/success";
import summary from "./svgs/summary";
import support from "./svgs/support";
import switcher from "./svgs/switcher";
import unauthorized from "./svgs/unauthorized";
import verify from "./svgs/verify";
import waitingList from "./svgs/waitingList";
import withdraw from "./svgs/withdraw";
import cardNoSelected from "./svgs/cardNoSelected";
import cardSelected from "./svgs/cardSelected";
import homeSelected from "./svgs/homeSelected";
import userNoSelected from "./svgs/userNoSelected";
import userSelected from "./svgs/userSelected";
import languageSelected from "./svgs/languageSelected";
import languageNoSelected from "./svgs/languageNoSelected";
import menuNoSelected from "./svgs/menuNoSelected";
import homeItemWhite from "./svgs/homeItemWhite";
import cardItemWhite from "./svgs/cardItemWhite";
import profileItemWhite from "./svgs/profileItemWhite";
import languageItemWhite from "./svgs/languageItemWhite";
import menuItemWhite from "./svgs/menuItemWhite";
import approvedIcon from "./svgs/approvedIcon";
import reprovedIcon from "./svgs/reprovedIcon";
import analysisIcon from "./svgs/analysisIcon";
import copyThin from "./svgs/copyThin";
import icoUser from "./svgs/ico-user";
import arrowAside from "./svgs/arrowAside";
import depositIcon from "./svgs/depositIcon";
import homeIcon from "./svgs/homeIcon";
import swapIcon from "./svgs/swapIcon";
import withdrawIcon from "./svgs/withdrawIcon";
import historyIcon from "./svgs/historyIcon";
import helpIcon from "./svgs/helpIcon";
import logoutIcon from "./svgs/logoutIcon";
import arrowDownSecond from "./svgs/arrowDownSecond";
import collapseLeft from "./svgs/collapseLeft";
import collapseRight from "./svgs/collapseRight";
import eyeWhite from "./svgs/eyeWhite";
import eyeWhiteHide from "./svgs/eyeWhiteHide";
import depositButton from "./svgs/depositButton";
import withdrawButton from "./svgs/withdrawButton";
import swapIconNew from "./svgs/swapIconNew";
import currencyTest from "./svgs/currencyTest";
import arrowSwap from "./svgs/arrowSwap";
import assetIcon from "./svgs/assetIcon";
import depositBlue from "./svgs/depositBlue";
import tradeBlue from "./svgs/tradeBlue";
import withdrawBlue from "./svgs/withdrawBlue";
import newFeature from "./svgs/newFeature";
import chevronRight from "./svgs/chevronRight";
import searchLg from "./svgs/searchLg";
import lineChartUp from "./svgs/lineChartUp";
import check from "./svgs/check";
import operateIcon from "./svgs/operateIcon";
import withdrawIconInv from "./svgs/withdrawIconInv";
import arrowDeposit from "./svgs/arrow-deposit";
import arrowSwapNav from "./svgs/arrow-swap";
import cardIcon from "./svgs/cardIcon";
import newCloseEye from "./svgs/newCloseEye";
import newEye from "./svgs/newEye";
import arrowUpGreen from "./svgs/arrowUpGreen";
import arrowDownGrey from "./svgs/arrowDownTagGrey";
import chevronGrey from "./svgs/chevronGrey";
import wallet from "./svgs/wallet";
import infoCircle from "./svgs/info-circle";
import portfolioIcon from "./svgs/portfolioIcon";
import currenciesIcon from "./svgs/currenciesIcon";
import portfolioDefault from "./svgs/portfolioDefault";
import verifyCodeModal from "./svgs/icon-modal-verifycode";
import timerIcon from "./svgs/timer-icon";
import { DetailedHTMLProps, HTMLAttributes } from "react";

const availableIcons = {
  ArrowLeftCalendar,
  ArrowRightCalendar,
  add,
  arrowBack,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  arrowSwapNav,
  arrowDeposit,
  attention,
  bell,
  calendar,
  cellPhone,
  close,
  company,
  convert,
  copy,
  cotation,
  deposit,
  edit,
  email,
  error,
  expired,
  eye,
  eyeHide,
  faq,
  filter,
  first,
  graphDown,
  graphUp,
  history,
  homeNoSelected,
  homeSelected,
  important,
  info,
  key,
  last,
  link,
  logout,
  maintenance,
  noConnection,
  none,
  noResult,
  ok,
  orderBy,
  orderDown,
  orderUp,
  print,
  question,
  receipt,
  remove,
  repeat,
  save,
  search,
  security,
  send,
  success,
  summary,
  support,
  switcher,
  unauthorized,
  userNoSelected,
  userSelected,
  verify,
  waitingList,
  withdraw,
  cardNoSelected,
  cardSelected,
  languageSelected,
  languageNoSelected,
  menuNoSelected,
  homeItemWhite,
  cardItemWhite,
  profileItemWhite,
  languageItemWhite,
  menuItemWhite,
  analysisIcon,
  approvedIcon,
  reprovedIcon,
  copyThin,
  okDefault,
  icoUser,
  arrowAside,
  depositIcon,
  homeIcon,
  swapIcon,
  withdrawIcon,
  historyIcon,
  helpIcon,
  logoutIcon,
  arrowDownSecond,
  collapseLeft,
  collapseRight,
  eyeWhite,
  eyeWhiteHide,
  depositButton,
  withdrawButton,
  swapIconNew,
  currencyTest,
  arrowSwap,
  assetIcon,
  depositBlue,
  tradeBlue,
  withdrawBlue,
  newFeature,
  chevronRight,
  searchLg,
  operateIcon,
  withdrawIconInv,
  cardIcon,
  newCloseEye,
  newEye,
  lineChartUp,
  check,
  portfolioIcon,
  currenciesIcon,
  arrowUpGreen,
  arrowDownGrey,
  chevronGrey,
  wallet,
  infoCircle,
  portfolioDefault,
  verifyCodeModal,
  timerIcon,
};

export type AvailableIconsType = keyof typeof availableIcons;

type IconProps = {
  icon: AvailableIconsType;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const getDynamicIcon = (icon: AvailableIconsType) => {
  const DynamicComponent = availableIcons?.[icon];
  return DynamicComponent ? <DynamicComponent /> : null;
};

export default function Icon({ icon, id, ...props }: IconProps) {
  return (
    <i {...props} id={id} className={`Icon ${styles.container}`}>
      {getDynamicIcon(icon)}
    </i>
  );
}
