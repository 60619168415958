import { t } from "i18next";
import * as S from "./styles";
import CurrencySlider from "@/pages/auth/components/CurrencySlider";
const MobileAppCard = () => {
  return (
    <S.MobileAppCardContainer>
      <S.TextContainer>
        <S.TextBlack>{t("loginForm.titleNegotiation")}</S.TextBlack>
        <S.TitleBlue>{t("loginForm.subTitleNegotiation")}</S.TitleBlue>
        <S.TextDetails>{t("loginForm.textNegotiation")}</S.TextDetails>
      </S.TextContainer>
      <S.ImageContainer>
        <img src="/images/app-mobile-sample.svg" />
      </S.ImageContainer>
      <S.ContentDownload>
        <h4>{t("loginForm.download")}</h4>
        <S.Download>
          <S.Button
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.transfero.crypto&hl=en_US",
                "_blank"
              )
            }
          >
            <img
              className="img-google"
              src="/images/GooglePlayLogo.svg"
              alt=""
            />
            Google Play
          </S.Button>

          <S.Button
            onClick={() =>
              window.open(
                "https://apps.apple.com/br/app/transfero/id1660074684",
                "_blank"
              )
            }
          >
            <img src="/images/appStore.svg" alt="" />
            App Store
          </S.Button>
        </S.Download>
      </S.ContentDownload>
      <S.ContentSlider>
        <CurrencySlider />
      </S.ContentSlider>
    </S.MobileAppCardContainer>
  );
};

export default MobileAppCard;
