import * as S from "./styles";

type CryptoMarketProps = {
  name: string;
  value: string;
  variation: number;
  absoluteImageUrl?: string;
};

export default function CryptoMarket({
  name,
  value,
  absoluteImageUrl,
}: CryptoMarketProps) {
  const src = absoluteImageUrl
    ? absoluteImageUrl
    : `/images/currencies/${name.toLowerCase()}.svg`;
  return (
    <S.ContentCryptoMarketProps>
      <S.Icon>
        <img src={src} alt={name} />
      </S.Icon>

      <S.Info>
        <S.Currency>{name}</S.Currency>
        <S.Amount>{value}</S.Amount>
        {/* {variation !== 0 && (
          <S.Plus $variation={variation >= 0 ? "blue" : "red"}>
            {" "}
            {variation}{" "}
          </S.Plus>
        )} */}
      </S.Info>
    </S.ContentCryptoMarketProps>
  );
}
