import { t } from "i18next";

export function humanizeStatus(q: string): string {
  const status = {
    withdraw: t("investmentsLayout.withdraw"),
    investment: t("investmentsLayout.deposit"),
  };

  return status[q as keyof typeof status];
}
export function formatCurrency(value: number): string {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value || 0);
}

export function roundPercentageData(value: number) {
  return Math.round(value);
}
