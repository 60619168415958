import { MouseEvent, KeyboardEvent, useEffect, useState } from "react";
import Link from "next/link";
import { Icon, IconLabel } from "@/widgets";

import styles from "./index.module.scss";

type ItemType = {
  label: string;
  src: string;
  href?: string;
  isCurrent?: boolean;
  id?: string;
};

type DropdownMenuProps = {
  items: ItemType[];
};

export default function DropdownMenu({ items }: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const current = items.filter((item: ItemType) => item.isCurrent);
  const otherItems = items.filter((item: ItemType) => !item.isCurrent);

  if (current.length != 1) {
    throw "The component DropdownMenu must have 1 current item.";
  }

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "Tab") {
      return;
    }

    event.preventDefault();

    if (event.code === "Space" || event.code === "Enter") {
      setIsOpen(!isOpen);
    }
  };

  const handleDocumentOnClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentOnClick);

    return () => {
      window.removeEventListener("click", handleDocumentOnClick);
    };
  }, []);

  return (
    <div
      className={`DropdownMenu ${styles.container} ${
        isOpen ? styles.isOpen : ""
      }`}
    >
      <div
        className={styles.current}
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={handleOnKeyDown}
      >
        <IconLabel
          id={current[0].id}
          size="large"
          label={current[0].label}
          src={current[0].src}
        />
        <Icon id="data-test-ico-arrow-down" icon="arrowDown" />
      </div>
      <div className={styles.options}>
        <ul>
          {otherItems.map((item: ItemType, index: number) => (
            <li key={index}>
              <Link href={item.href ? item.href : "/"}>
                <IconLabel
                  id={item.id}
                  size="default"
                  label={item.label}
                  src={item.src}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
