import { MongoAbility } from "@casl/ability";
import React, { useEffect } from "react";
import { createContextualCan } from "@casl/react";
import { createContext, FC } from "react";
import { useFeatureFlags } from "@/presentation/hooks";
import { useRouter } from "next/router";
import { redirectNotAbility } from "./config";
import { SplashScreen } from "@/widgets/SplashScreen";

export type Actions =
  | "Create_Virtual_Cards"
  | "Reward_Program"
  | "African_Withdrawal"
  | "Cards"
  | "Investments"
  | "Slip_Payments";
export type Subjets = "All";
export type AppAbility = MongoAbility<[Actions, Subjets]>;

export const AbilityContext = createContext<AppAbility>({} as AppAbility);
const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider: FC<{ children: React.JSX.Element }> = ({ children }) => {
  const { abilities, isLoadingAll, customer } = useFeatureFlags();
  const navigate = useRouter();

  useEffect(() => {
    if (abilities && customer) {
      redirectNotAbility(abilities, navigate, customer?.isPrime);
    }
  }, [abilities, navigate, isLoadingAll]);

  if (isLoadingAll) {
    return <SplashScreen />;
  }

  return (
    <AbilityContext.Provider value={abilities}>
      {children}
    </AbilityContext.Provider>
  );
};

AbilityProvider.displayName = "AbilityProvider";

export { AbilityProvider, Can };
