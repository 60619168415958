import styled, { css, keyframes } from "styled-components";
interface ButtonContainerProps {
  $collapsed?: boolean;
  $open?: boolean;
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  width: 280px;
  height: 56px;
  margin: 14px 16px 8px 16px;
  padding-block: 8px;
`;

export const ContentInitial = styled.div`
  width: 40px;
  height: 40px;
`;

export const Cnt = styled.div`
  width: 198px;
  position: absolute;
  top: 0;
`;

export const PersonImage = styled.div`
  display: flex;
  margin: 5px 5px 0px 5px;
`;

export const InfoUser = styled.div<ButtonContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  height: 39px;
  ${(props) =>
    !props.$collapsed &&
    css`
      animation: ${fadeInAnimation} 0.3s ease-in-out;
    `};

  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    margin-bottom: 4px;
    color: #4a5578;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.03px;
    color: #4a5578;
  }
`;

export const Arrow = styled.div<ButtonContainerProps>`
  display: flex;
  margin-left: 18px;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${(props) => props.$open && "rotate(90deg) translateY(2%)"};
  ${(props) =>
    !props.$collapsed &&
    css`
      animation: ${fadeInAnimation} 0.7s ease-in-out;
    `};
`;

export const Divider = styled.div<ButtonContainerProps>`
  border: 1px solid #dcdfea;
  border-bottom: 0px;
  width: "198px";
`;

export const ContentSubMenuContainer = styled.div``;

export const SubMenuContainer = styled.ul<ButtonContainerProps>`
  width: 198px;
  position: absolute;
  top: 89px;
  background-color: #ffffff;
  /* height: 109px; */
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #dcdfea;
  cursor: default;
  // padding-top: 8px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  ${(props) =>
    !props.$collapsed &&
    css`
      animation: ${fadeInAnimation} 0.3s ease-in-out;
    `};
`;

export const SubMenuItem = styled.li<{ border?: string }>`
  width: 100%;
  height: 31px;
  padding: 20px 16px;
  gap: 8px;
  opacity: 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-top: ${(props) =>
    props.border === "top" ? "1px solid #EAECF0" : "none"};
  color: ${(props) => (props.border === "top" ? "#D92D20" : "#4a5578")};

  &:hover {
    background-color: #eaecf0;
  }
`;

export const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #413fcf;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  padding: 8px;
`;
