import { createNumberMask } from "text-mask-addons";
import { ICurrency } from "@/models";

const _usdMask = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 8,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const usdMask = createNumberMask(_usdMask);

const _brlMask = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const brlMask = createNumberMask(_brlMask);

const _onlyNumberMask = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 99,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const onlyNumberMask = createNumberMask(_onlyNumberMask);

export const currencyMaskFactory = (
  currency: ICurrency,
  thousandsSeparatorSymbol: string,
  decimalSymbol: string,
  integerLimit: number = 12
) => {
  const config = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: thousandsSeparatorSymbol,
    allowDecimal: true,
    decimalSymbol: decimalSymbol,
    decimalLimit: currency.decimalDigits,
    integerLimit: integerLimit,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  return createNumberMask(config);
};

export const unitPriceMask = (unitPrice: number) => {
  return unitPrice
    .toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: 4,
    })
    .replace(",", ".");
};
