import { capitalizeWords } from "@/utils";
import { Image } from "@/widgets";

import styles from "./index.module.scss";

type LogoProps = {
  asLink?: boolean;
  href?: string;
  variant?:
    | "transfero-crypto"
    | "transfero"
    | "transfero-2"
    | "transfero-new"
    | "transfero-white"
    | "transfero-icon"
    | "white-small";
};

export default function Logo({
  asLink = false,
  href,
  variant = "transfero-2",
}: LogoProps) {
  const title = capitalizeWords(variant.replace("-", " "));
  const ImageComponent = (
    <Image src={`/images/logo-${variant}.svg`} alt={title} priority />
  );
  const containerClass = `Logo ${styles.container}`;

  if (asLink) {
    if (href) {
      return (
        <div className={containerClass}>
          <a href={href} title={title}>
            {ImageComponent}
          </a>
        </div>
      );
    } else {
      throw "'href' attribute is missing on the 'Logo' component with asLink as true";
    }
  }

  return (
    <div className={containerClass} title={title}>
      {ImageComponent}
    </div>
  );
}
