import { useEffect, useState } from "react";
import { Router, useRouter } from "next/router";
import type { AppProps } from "next/app";
import { ZendeskAPI } from "react-zendesk";
import NProgress from "nprogress";
import {
  Footer,
  HeaderContainer,
  InitialLoader,
  Providers,
  ZendeskSupport,
} from "@/components";
import { BaseLayout } from "@/widgets";
import { BaseLayoutType } from "@/widgets/BaseLayout/types";
import { RootState } from "@/store";
import "nprogress/nprogress.css";
import "@/styles/globals.scss";
import "../../i18n";
import i18n from "../../i18n";
import Sidebar from "@/components/menus/Sidebar";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import MobileAppCard from "../components/MobileAppCard";
import MobileHeader from "../components/headers/MobileHeader";
import MobileFooter from "@/components/Footer/MobileFooter";
import LimitsPage from "./help-center/limits-info/index.page";
import FeesPage from "./help-center/fees-info/index.page";
import TermsPage from "./help-center/terms/index.page";
import PrivacyPage from "./help-center/privacy-policies/index.page";
import ContactPage from "./help-center/contact/index.page";
import React from "react";
import * as S from "./styles";
import { AbilityProvider } from "@/presentation/libs/casl/context";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/libs/react-query";

type LayoutProps = {
  layout: BaseLayoutType;
  initialState: RootState;
};

export default function App({ Component, pageProps }: AppProps<LayoutProps>) {
  const { events } = Router;
  const { locale } = useRouter();
  const location = useRouter();
  const { t } = useTranslation();
  const screenWidth = useScreenWidth();

  const layout = pageProps?.layout ?? "default";
  const [state, setState] = useState(false);

  events.on("routeChangeStart", () => NProgress.start());
  events.on("routeChangeComplete", () => NProgress.done());
  events.on("routeChangeError", () => NProgress.done());

  useEffect(() => {
    i18n.changeLanguage(locale);
    NProgress.configure({ easing: "linear", speed: 500 });
  }, []);

  const pageComponents: any = {
    "help-center/limits-info": LimitsPage,
    "help-center/fees-info": FeesPage,
    "help-center/terms": TermsPage,
    "help-center/privacy-policies": PrivacyPage,
    "help-center/contact": ContactPage,
  };

  const returnMobilePage = () => {
    const PageComponent = Object.keys(pageComponents).find((key) =>
      location.route.includes(key)
    );

    return (
      <>
        {PageComponent ? (
          <>
            <MobileHeader />
            {React.createElement(pageComponents[PageComponent])}
            <MobileFooter />
          </>
        ) : (
          <S.MobileContainer>
            <MobileHeader />
            <MobileAppCard />
            <MobileFooter />
          </S.MobileContainer>
        )}
      </>
    );
  };

  return (
    <>
      {screenWidth <= 780 ? (
        <QueryClientProvider client={queryClient}>
          <Providers>{returnMobilePage()}</Providers>
        </QueryClientProvider>
      ) : (
        <>
          <Head>
            <title>{t("authLayout.mainTitleWeb")}</title>
          </Head>
          <QueryClientProvider client={queryClient}>
            <AbilityProvider>
              <Providers>
                <>
                  <InitialLoader />
                  <BaseLayout
                    layout={layout}
                    menu={
                      <Sidebar
                        collapsed={state}
                        setCollapsed={() => setState(!state)}
                      />
                    }
                    header={<HeaderContainer />}
                    footer={<Footer />}
                    onFaq={() => {
                      ZendeskAPI("webWidget", "open");
                    }}
                  >
                    <Component {...pageProps} />
                  </BaseLayout>
                  <ZendeskSupport />
                </>
              </Providers>
            </AbilityProvider>
          </QueryClientProvider>
        </>
      )}
    </>
  );
}
