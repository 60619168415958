import styled from "styled-components";

export const ContentCryptoMarketProps = styled.div`
  width: calc(100% - 15px);
  height: 70px;
  display: flex;
  border: 1px solid #dcdfea;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`;

export const Icon = styled.div`
  display: flex;

  img {
    width: 22px;
    height: 22px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  margin-left: 10px;
`;

export const Currency = styled.h4`
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;

export const Amount = styled.p`
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;

export const Plus = styled.div<{ $variation: string }>`
  min-width: 30px;
  background-color: ${(props) =>
    props.$variation === "red" ? "transparent" : "#ECFDF3"};
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => (props.$variation === "red" ? "#D92D20" : "#027A48")};
  padding: 3px;
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 8px;
  font-weight: 600;
  padding: 0 10px;
`;
