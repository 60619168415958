import { api } from "@/apis/axios";
import { ICards } from "@/pages/card/types";
import { useQuery } from "@tanstack/react-query";
import { getCookie } from "cookies-next";

export function useCards() {
  const jwt = getCookie("transfero.crypto.web..token", {});
  const {
    data: cards,
    isLoading,
    refetch,
  } = useQuery<ICards[]>({
    queryKey: ["cards"],
    queryFn: async () => {
      const { data } = await api.get("cards");
      return data;
    },
    staleTime: 0,
    enabled: !!jwt,
  });

  return {
    cards,
    isLoading,
    refetch,
  };
}
